export const toTime = (min_num) => {
    let hours = min_num ? Math.floor((min_num / 60 / 60)) : 0;
    let minutes = min_num ? Math.ceil((min_num / 60) % 60) : 0;

    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    // if (seconds < 10) {
    //     seconds = "0" + seconds;
    // }
    return hours + ":" + minutes;
};