<template>
  <container>
    <!-- <v-header/> -->
    <div class="how-long-wrap">
      <BtnMenu />
      <div class="d-flex justify-center align-center pos-r">
        <h1 class="h1">Как долго заряжаться</h1>
        <a @click="modelAlert">
          <svg-icon
            class="alert-circle pointer pos-r"
            name="alert-circle"
          ></svg-icon>
        </a>
        <div v-if="isAlert" class="alert pos-a">
          <div class="pos-r">
            <a @click="modelAlert">
              <svg-icon
                class="pos-a cross pointer"
                height="24"
                name="x"
                width="24"
              ></svg-icon>
            </a>
            <p class="alert__text">
              Как долго будет заряжаться Taycan, зависит от таких условий, как
              комплектация и тип зарядного устройства. Используйте калькулятор,
              чтобы увидеть приблизительные значения.
            </p>
          </div>
        </div>
      </div>
      <div class="constructor-wrapper">
        <div
          :class="{ switched: !isSwitch }"
          class="constructor-line d-flex justify-between align-center"
        >
          <div class="constructor-text">
            <h2 class="h2">Источник питания</h2>
          </div>
          <div class="constructor-btn">
            <div class="constructor-switches d-flex">
              <div
                :class="{ active: isSwitch }"
                class="constructor-switches__item"
                @click="setSwitch(true)"
              >
                Дом / парковка
              </div>
              <div
                :class="{ active: !isSwitch }"
                class="constructor-switches__item"
                @click="setSwitch(false)"
              >
                Город / трасса
              </div>
            </div>
            <div
              class="
                constructor-btn__item-wrapper constructor-btn__item-wrapper-m
              "
            >
              <div v-if="isSwitch" class="d-flex">
                <div
                  v-for="(i, id) in BtnGroup1"
                  :key="i.text"
                  :class="{ active: selected[0].select === id }"
                  class="constructor-btn__item"
                  @click="setBtnItem(id, 0, i.text)"
                >
                  {{ i.text + " кВт" }}
                </div>
              </div>
              <div v-else class="d-flex">
                <div
                  v-for="(i, id) in BtnGroup4"
                  :key="i.text"
                  :class="{ active: selected[3].select === id }"
                  class="constructor-btn__item large"
                  @click="setBtnItem(id, 3, i.text)"
                >
                  {{ i.text + " кВт" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{ switched: !isSwitch }"
          class="constructor-line d-flex justify-between align-center"
        >
          <div class="constructor-text">
            <h2 :class="{ disabled: !isSwitch }" class="h2">
              Адаптер Mobile Charger
            </h2>
            <div v-if="!isSwitch" class="description-mobile-charger d-flex">
              <svg-icon name="alert-circle-white" />
              <p>Не требуется при зарядке постоянным током</p>
            </div>
          </div>
          <div class="constructor-btn">
            <div
              :class="{ disabled: !isSwitch }"
              class="constructor-btn__item-wrapper d-flex"
            >
              <div
                v-for="(i, id) in BtnGroup2"
                :key="i.text"
                :class="{ active: selected[1].select === id && isSwitch }"
                class="constructor-btn__item"
                @click="setBtnItem(id, 1, i.text)"
              >
                {{ i.text + " кВт" }}
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{ switched: !isSwitch }"
          class="constructor-line d-flex justify-between align-center"
        >
          <div class="constructor-text">
            <h2 class="h2">Тип коннектора</h2>
          </div>
          <div class="constructor-btn d-flex">
            <div
              class="constructor__connector-img-wrapper d-flex justify-between"
            >
              <img
                :src="
                  require(`@/assets/img/connector-ccs-gray${
                    !isSwitch && selected[3].select !== 0 ? '-selected' : ''
                  }.png`)
                "
                alt=""
                class="constructor__connector-img"
              />
              <img
                :src="
                  require(`@/assets/img/connector-type2-gray${
                    isSwitch || (!isSwitch && selected[3].select) === 0
                      ? '-selected'
                      : ''
                  }.png`)
                "
                alt=""
                class="constructor__connector-img"
              />
            </div>
            <div
              :class="{ disabled: !isSwitch && selected[3].select !== 0 }"
              class="constructor-btn__item-wrapper d-flex pos-r"
            >
              <div
                v-for="(i, id) in BtnGroup3"
                :key="i.text"
                :class="{
                  active:
                    (selected[2].select === id && isSwitch) ||
                    (selected[2].select === id &&
                      !isSwitch &&
                      selected[3].select === 0),
                }"
                class="constructor-btn__item"
                @click="setBtnItem(id, 2, i.text)"
              >
                {{ i.text + " кВт" }}
              </div>
              <span class="pos-a constructor-btn__item-description"
                >Бортовое зарядное устройство</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="charge-calculator-wrapper">
        <div class="charge-calculator d-flex justify-between">
          <div class="charge-calculator__slider">
            <h2 class="h2">Введите ваш средний дневной пробег</h2>
            <div class="time-slider-wrap">
              <div id="time-slider" class="time-slider">
                <div id="slider-start" class="slider-start">0</div>
                <div id="slider-end" class="slider-end">250</div>
              </div>
            </div>
          </div>
          <div class="charge-calculator__arrow d-flex align-end">
            <svg-icon height="40" name="arrow-left-and-right" width="39" />
          </div>
          <div class="charge-calculator__time">
            <h2 class="h2">Сколько часов вы готовы выделить на зарядку</h2>
            <div class="calculator__time-wrapper d-flex align-center">
              <span class="calculator-btn" @click="editTime('-')">
                <svg-icon
                  height="100%"
                  name="calculator-btn-minus"
                  width="100%"
                />
              </span>
              <div class="time">
                <span>{{ prettyTime }}</span>
                <div class="time-text d-flex justify-between">
                  <div class="time-text__item">часов</div>
                  <div class="time-text__item">минут</div>
                </div>
              </div>
              <span class="calculator-btn" @click="editTime('+')">
                <svg-icon
                  height="100%"
                  name="calculator-btn-plus"
                  width="100%"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="charging-cost-wrapper d-flex justify-between">
        <div class="charging-cost__price">
          <h2 class="h2">Стоимость зарядки</h2>
          <div class="price">
            {{ price ? price : "0.00" }} <span class="price-text">рублей</span>
          </div>
        </div>
        <div class="charging-cost__input">
          <app-input
            :search-data="searchData"
            class="input"
            placeholder="Регион"
            valueInput="Москва"
            @inputValue="inputValueFunc"
          />
          <div
            class="input__text d-flex align-center label-checkbox"
            @click="setNightTariff"
          >
            <p>Ночной тариф</p>
            <svg-icon
              :name="isNightTariff ? 'checkbox-white' : 'checkbox-empty-white'"
              class="charging-cost__checkbox"
            />
          </div>
        </div>
      </div>
      <div class="description-tariff d-flex">
        <svg-icon name="alert-circle-white" />
        <p>
          Уточните у менеджера какие опции вам потребуются для зарядки от
          каждого источника. При расчетах используется усредненный расход заряда
          на километр пути. Реальные значения могут отличаться как в большую,
          так и в меньшую сторону.
        </p>
      </div>
    </div>
  </container>
</template>

<script>
/* import VHeader from "@/components/VHeader"; */
import Container from "@/components/Container/Container";
import SvgIcon from "@/components/IconBase";
import noUiSlider from "nouislider";
import $ from "jquery";
import AppInput from "@/components/AppInput";
import { toTime } from "@/functions/toTime";
import BtnMenu from "../components/BtnMenu.vue";
import rates from "../rates.json";

export default {
  name: "VHowLongToCharge",
  components: { AppInput, SvgIcon, Container, BtnMenu },
  data() {
    return {
      isAlert: false,
      isSwitch: true,

      isNightTariff: false, // ночной трафик
      searchInputValue: "", // input
      time: 600, // time minutes
      sliderValue: 80,
      price: 0.0,
      group1: 3.6,
      group2: 11,
      group3: 11,
      midleProb: 340,
      acum: 90,
      rate: 2.647,
      Kv: 3.6,
      calcFun: true,
      tariffD: 0,
      tariffN: 0,
      timefull: {
        home: {
          3.6: 25,
          7.2: 12.5,
          11: 8.2,
          22: 4.1,
        },
        city: {
          11: 8.2,
          22: 4.1,
          150: 1.3,
          270: 1.1,
        },
      },
      selected: [
        { group: 0, select: 0 },
        { group: 1, select: 0 },
        { group: 2, select: 0 },
        { group: 3, select: 0 },
      ],
      BtnGroup1: [
        {
          text: "3,6",
        },
        {
          text: "7,2",
        },
        {
          text: "11",
        },
        {
          text: "22",
        },
      ],
      BtnGroup2: [{ text: "11" }, { text: "22" }],
      BtnGroup3: [{ text: "11" }, { text: "22" }],
      BtnGroup4: [{ text: "22" }, { text: "150" }, { text: "270" }],
      searchData: [],
    };
  },
  methods: {
    inputValueFunc(val) {
      this.searchInputValue = val;
    },

    modelAlert() {
      this.isAlert = !this.isAlert;
    },

    setNightTariff() {
      this.isNightTariff = !this.isNightTariff;
      this.calcPrice();
    },

    setSwitch(is) {
      this.isSwitch = is;
      // for (let i = 0; i < 4; i++) {
      //   this.setBtnItem(0, i)
      // }
    },
    calcTime() {
      let Kv = Math.min(this.group1, this.group2, this.group3);
      this.Kv = Kv;
      let area = this.isSwitch ? "home" : "city";
      let timeArea = this.timefull[area][this.Kv];

      let totalTime = Math.round(
        ((timeArea / this.midleProb) * this.sliderValue).toFixed(2) * 60
      );
      this.time = Math.round(totalTime / 15) * 15;
      this.calcPrice();
    },
    setBtnItem(id, group, val) {
      let str = val;
      if (val.includes(",")) {
        str = val.replace(",", ".");
      }
      let num = Number(str);
      this.selected[group].select = id;
      if (group === 0 || group === 3) {
        this.group1 = num;
      } else if (group === 1) {
        this.group2 = num;
      } else {
        this.group3 = num;
      }

      if (!this.isSwitch && this.selected[3].select != 0) {
        this.group3 = 1000;
      }
      if (!this.isSwitch && this.selected[3].select == 0) {
        this.group3 = this.BtnGroup3[this.selected[2].select].text;
      }
      if (this.group1 >= 150) {
        this.group2 = 1000;
        this.group3 = 1000;
      }
      if (this.calcFun) {
        this.calcTime();
      } else {
        this.calcPut();
        setTimeout(() => {
          if (this.sliderValue >= 250) {
            this.calcTime();
          }
        }, 100);
      }
    },

    editTime(sign) {
      if (sign === "+") {
        if (this.sliderValue != 250) {
          this.time += 30;
        }
      } else if (this.time > 30) {
        this.time -= 30;
      } else if (this.time <= 30) {
        this.time = 0;
      }
      this.calcFun = false;
      if (!(sign === "+" && this.sliderValue == 250)) {
        this.calcPut();
        if (this.sliderValue == 250) {
          this.calcTime();
        }
      }
    },
    calcPut() {
      let Kv = Math.min(this.group1, this.group2, this.group3);
      this.Kv = Kv;
      let area = this.isSwitch ? "home" : "city";
      let timeArea = this.timefull[area][this.Kv];
      let prob = Math.floor(((this.midleProb / timeArea) * this.time) / 60);
      this.sliderValue =
        Math.round(prob / 10) * 10 >= 250 ? 250 : Math.round(prob / 10) * 10;
      this.calcPrice();
    },
    calcPrice() {
      if (this.isNightTariff) {
        //this.price = (((this.Kv * this.time) / 60) * this.tariffN).toFixed(2);
        this.price = ((90 / 340) * this.sliderValue * this.tariffN).toFixed(2);
      } else {
        //this.price = (((this.Kv * this.time) / 60) * this.tariffD).toFixed(2);
        this.price = ((90 / 340) * this.sliderValue * this.tariffD).toFixed(2);
      }
    },
  },
  watch: {
    searchInputValue(val) {
      if (val) {
        rates.forEach((element) => {
          if (element.region == val) {
            this.tariffN = element.nightTarif.replace(",", ".");
            this.tariffD = element.dayzone.replace(",", ".");
            this.calcPrice();
          }
        });
      } else {
        this.tariffN = 0;
        this.tariffD = 0;
        this.calcPrice();
      }
    },
    sliderValue(val) {
      let temp_slider = document.getElementById("time-slider");
      temp_slider.noUiSlider.set(val);
    },
    isSwitch(val) {
      if (val) {
        this.group1 = this.BtnGroup1[this.selected[0].select].text.replace(
          ",",
          "."
        );
        this.group2 = this.BtnGroup2[this.selected[1].select].text.replace(
          ",",
          "."
        );
        this.group3 = this.BtnGroup3[this.selected[2].select].text.replace(
          ",",
          "."
        );
      } else {
        this.group1 = this.BtnGroup4[this.selected[3].select].text.replace(
          ",",
          "."
        );
      }
      if (!val && this.selected[3].select === 0) {
        this.group2 = 10000;
      }
      if (val) {
        this.group2 = this.BtnGroup2[this.selected[1].select].text.replace(
          ",",
          "."
        );
      }
      if (!val) {
        this.group1 = this.BtnGroup4[this.selected[3].select].text.replace(
          ",",
          "."
        );
      }
      if (this.group1 >= 150) {
        this.group2 = 1000;
        this.group3 = 1000;
      }
      if (this.calcFun) {
        this.calcTime();
      } else {
        this.calcPut();
        setTimeout(() => {
          if (this.sliderValue >= 250) {
            this.calcTime();
          }
        }, 100);
      }
    },
  },

  computed: {
    prettyTime() {
      return toTime(this.time * 60);
    },
  },
  mounted() {
    let temp_slider = document.getElementById("time-slider");
    noUiSlider.create(temp_slider, {
      start: [80],
      connect: "lower",
      step: 10,
      range: {
        min: [0],
        max: [250],
      },
    });
    temp_slider.addEventListener("click", () => {
      this.calcFun = true;
    });
    $("#time-slider .noUi-handle").append(
      '<div class="current-temp">80 км</div>'
    );
    const sliderEd = $(".slider-end");
    const sliderStart = $(".slider-start");
    const currentTemp = $("#time-slider .current-temp");
    temp_slider.noUiSlider.on("update", (values, handle) => {
      let temp = Math.round(values[handle]);
      currentTemp.html(temp + " км");
      if (temp >= 200) sliderEd.addClass("d-none");
      else sliderEd.removeClass("d-none");
      if (temp <= 20) sliderStart.addClass("d-none");
      else sliderStart.removeClass("d-none");
    });
    temp_slider.noUiSlider.on("change", (values, handle) => {
      let temp = Math.round(values[handle]);
      this.sliderValue = temp;
      this.calcTime();
    });
    rates.forEach((element) => {
      this.searchData.push({ name: element.region });
    });
    this.calcTime();
  },
};
</script>

<style lang="scss" scoped>
.charging-cost__price,
.charging-cost__input p,
.description-tariff,
.charge-calculator__time {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>